import deepmerge from 'deepmerge';
import type { Config, DeepPartial } from '../default/types';
import defaultConfig, { getFullLocaleFromConfig, environment, customArrayMerge } from '../default';

const tenantId = 'jelmolich';

const config = deepmerge<Config, DeepPartial<Config>>(
  defaultConfig,
  {
    host: { name: tenantId, domain: 'jelmoli-shop.ch' },
    clientId: 'JelmoliCh',
    i18n: {
      defaultLocale: 'de-CH',
      locales: ['de-CH', 'fr-CH'],
      languages: ['de', 'fr'],
    },
    tenantId,
    styleVariant: 'dark',
    company: {
      name: 'Jelmoli',
      seoTitleName: 'jelmoli-shop.ch',
    },
    headerSlots: {
      localeSwitcher: true,
      warehouse: true,
      serviceApi: `https://storage.googleapis.com/drupal-pages/${environment}/menus/${tenantId}/<locale>/bucket-service-flyout.json`,
    },
    assets: {
      cmsImage: {
        imageOptimizationEnabled: true,
        uriTransformEndpoint: `https://storage.googleapis.com/drupal-images-${tenantId}/$1/`,
      },
    },
    staticContent: {
      apiEndpoints: {
        webappJson: `https://cms.${tenantId}.empirie.<envTld>/<locale>/<path>?_format=webapp_json&flat`,
        bucket: `https://storage.googleapis.com/drupal-pages/${environment}<subfolder>/${tenantId}/<locale>/<identifier>.json`,
      },
      ssg: {
        prerenderAtBuildTime: [
          { params: { cmsPage: ['storefront'] }, locale: 'de' },
          { params: { cmsPage: ['storefront'] }, locale: 'fr' },
          { params: { cmsPage: ['service-hilfe'] }, locale: 'de' },
          { params: { cmsPage: ['aide-service'] }, locale: 'fr' },
        ],
      },
      cmsFontToTheme: {
        '.OttoSansBold, .font-normal-700': {
          fontWeight: 'fontWeightBold',
        },
        '.ManropeThin, .OttoSansThin, .font-normal-300': {
          fontWeight: 'fontWeightLight',
        },
      },
    },
    product: {
      cliplister: {
        customerId: '160382',
      },
      flixmedia: {
        distributorId: 18442,
        enabled: true,
      },
      price: {
        hasSalePrimaryColor: false,
      },
      hasScarcity: true,
    },
    installmentCalculator: {
      hasLegalHints: false,
      InstallmentCalculatorCallToActionLayout: {
        layout: 'Layout3',
        openCalculatorIn: { type: 'Modal' },
      },
      hasSpecialCreditColors: true,
    },
    search: {
      adServer: {
        mode: 'containerOnly',
      },
    },
    order: {
      seals: {},
      assets: {
        gifs: {
          success: {
            url: 'https://bilder.jelmoli-shop.ch/i/empiriecom/io/de/thank_you_1_transparent.gif',
          },
        },
      },
      premium: {
        enabled: true,
      },
    },
    footer: {
      apiEndpoints: {
        bucket: `https://storage.googleapis.com/drupal-pages/${environment}<subfolder>/${tenantId}/<locale>/<identifier>.json`,
      },
    },
    forms: {
      apiAgnitasUrl: {
        values: {
          useDOI: false,
        },
      },
      contact: {
        selectOptionsSubject: [
          { label: 'forms.type.subject.option.articleError', value: 'articleError' },
          { label: 'forms.type.subject.option.changed.email', value: 'emailChanged' },
          { label: 'forms.type.subject.option.changed.data', value: 'dataChanged' },
          { label: 'forms.type.subject.option.account', value: 'account' },
          { label: 'forms.type.subject.option.retoure', value: 'retoure' },
          { label: 'forms.type.subject.option.product', value: 'product' },
          { label: 'forms.type.subject.option.delivery', value: 'delivery' },
          { label: 'forms.type.subject.option.web', value: 'web' },
          { label: 'forms.type.subject.option.complaint.comment', value: 'complaint' },
          { label: 'forms.type.subject.option.return.data', value: 'return' },
          { label: 'forms.type.subject.option.coupons', value: 'coupons' },
          { label: 'forms.type.subject.option.newsletter', value: 'newsletter' },
          { label: 'forms.type.subject.option.other', value: 'other' },
        ],
      },
      newsletterUnsubscribe: {
        useDedicatedSubmitButtonTitleFor: ['unsubscribe|99'],
        fields: [
          {
            id: 'newsletter',
            formType: 'radio',
            defaultValue: 'unsubscribe|99',
            options: [
              {
                id: 'frq-daily',
                value: 'frequency|0',
                messageKey: 'forms.newsletter.unsubscribe.type.frequency.option.daily',
              },
              {
                id: 'frq-weekly',
                value: 'frequency|1',
                messageKey: 'forms.newsletter.unsubscribe.type.frequency.option.weekly',
              },
              {
                id: 'unsubscribe',
                value: 'unsubscribe|99',
                messageKey: 'forms.newsletter.unsubscribe.type.unsubscribe',
              },
              {
                id: 'pause',
                value: 'pause|-100',
                messageKey: 'forms.newsletter.unsubscribe.type.pause',
              },
            ],
            additionalForm: {
              id: 'pause',
              formType: 'select',
              showOnlyWhenWhatSelected: 'pause',
              messageKeyPlaceholder: 'forms.newsletter.unsubscribe.type.pause.label',
              options: [
                {
                  id: 'pause-7',
                  value: '7',
                  messageKey: 'forms.newsletter.unsubscribe.type.pause.option.7',
                },
                {
                  id: 'pause-14',
                  value: '14',
                  messageKey: 'forms.newsletter.unsubscribe.type.pause.option.14',
                },
                {
                  id: 'pause-21',
                  value: '21',
                  messageKey: 'forms.newsletter.unsubscribe.type.pause.option.21',
                },
                {
                  id: 'pause-30',
                  value: '30',
                  messageKey: 'forms.newsletter.unsubscribe.type.pause.option.30',
                },
              ],
            },
          },
        ],
      },
      newsletterSubscribe: {
        de: {
          unsubscribeLink:
            'https://www.jelmoli-shop.ch/service-hilfe/vorteile/newsletter-abmelden/',
          voucherLink: 'https://www.jelmoli-shop.ch/service-hilfe/vorteile/gutscheine-rabatte/',
        },
        fr: {
          unsubscribeLink:
            'https://www.jelmoli-shop.ch/fr/aide-service/avantages/desinscription-de-la-newsletter',
          voucherLink: 'https://www.jelmoli-shop.ch/fr/aide-service/avantages/bons-rabais/',
        },
      },
    },
    banner: {
      apiEndpoints: {
        bucket: `https://storage.googleapis.com/drupal-contentsnippet/${environment}/${tenantId}/<locale>/<identifier>.json`,
      },
    },
    nlSheetTexts: {
      apiEndpoints: {
        bucket: `https://storage.googleapis.com/drupal-contentsnippet/${environment}/${tenantId}/<locale>/nl-sheet.json`,
      },
    },
    serviceLayer: {
      apiEndpoints: {
        bucket: `https://storage.googleapis.com/drupal-contentsnippet/${environment}/${tenantId}/<locale>/<identifier>.json`,
        bucketPages: `https://storage.googleapis.com/drupal-pages/${environment}/${tenantId}/<locale>/<identifier>.json`,
      },
    },
    seoContent: {
      apiEndpoints: {
        bucket: `https://storage.googleapis.com/inspire-seofiles/<locale>_${tenantId}-<category>.json`,
      },
    },
    survey: {
      apiEndpoints: {
        bucket: `https://storage.googleapis.com/drupal-surveys/${environment}/${tenantId}/<locale>/<identifier>.json`,
      },
    },
    promotionBanner: {
      apiEndpoints: {
        bucketEmptyPromotions: `https://storage.googleapis.com/drupal-contentsnippet/${environment}/${tenantId}/<language>/empty-promotion.json`,
        bucketPromotion: `https://storage.googleapis.com/promotion-data/${tenantId}/<language>/<code>.json`,
        bucketContentSnippet: `https://storage.googleapis.com/drupal-contentsnippet/${environment}/${tenantId}/<language>/<identifier>.json`,
      },
    },
    voucher: {
      apiEndpoints: {
        bucket: `https://storage.googleapis.com/promotion-data/${tenantId}/<locale>/<identifier>.json`,
      },
    },
    appBanner: { appUrl: 'https://app.adjust.com/908rzj9_i331frl' },
    tracking: {
      soastaId: 'E5TKR-EUQHN-KSJKK-TGX8P-4US3H',
      tagmanagerId: 'GTM-5XXTXXK',
      googleSiteVerificationID: ['fZ1lyGUwe-g4AMGjHievvd2bqXuyPGQGB-lX4odp2pM'],
      msvalidateID: '4067315BE5669CD6C74BDEC6F6AC5D47',
      pinterestRichPinVerificationID: '8f3ef5e6b998a08858555b19af641e9c',
      schemaOrgOrganisation: {
        'de-CH': {
          addressCountry: 'CH',
          addressRegion: 'ZH',
          addressLocality: 'Otelfingen',
          alternateName: ['Jelmoli', 'Jelmoliversand'],
          areaServed: 'CH',
          contactUrl: 'https://www.jelmoli-shop.ch/service-hilfe/ueber-uns/kontakt',
          description:
            'Jelmoli-Versand  - der online Mode und Möbel Versand. Machen Sie sich eine Freude und entdecken Sie aktuelle Fashion- und Wohntrends.',
          email: 'service@jelmoliversand.ch',
          hoursAvailable: ['Mo-Su 7:00-22:00'],
          legalName: 'Jelmoli Versand AG',
          logo: `/assets/logos/${tenantId}/logo.svg`,
          name: 'Jelmoli Versand',
          productSupported: '(Festnetz CHF 0.14 / Min., Mobilfunkpreise max. CHF 0.42 / Min.)',
          sameAs: [
            'https://www.facebook.com/jelmoliversand/',
            'https://www.youtube.com/channel/UCJunBjLDyBOG2mQD-K1bkhQ',
            'https://www.instagram.com/jelmoliversand/',
            'https://de.wikipedia.org/wiki/Jelmoli',
          ],
          telephone: '+41848-840-300',
          url: 'https://www.jelmoli-shop.ch',
          postalCode: '8112',
          privacyNoticePath: '/service-hilfe/ueber-uns/datenschutz',
          potentialAction: {
            target: 'https://www.jelmoli-shop.ch/s/{search_term}/',
          },
          streetAddress: 'Industriestrasse 19',
        },
        'fr-CH': {
          addressCountry: 'CH',
          addressRegion: 'ZH',
          addressLocality: 'Otelfingen',
          alternateName: ['Jelmoli', 'Jelmoliversand'],
          areaServed: 'CH',
          contactEmail: 'service@jelmoliversand.ch',
          contactUrl:
            'https://www.jelmoli-shop.ch/fr/aide-service/qui-sommes-nous/mentions-legales',
          description:
            "Jelmoli-Versand - la société d'expédition de mode et de meubles en ligne. Faites-vous plaisir et découvrez les dernières tendances mode et living.",
          email: 'service@jelmoliversand.ch',
          hoursAvailable: ['Mo-Su 7:00-22:00'],
          legalName: 'Jelmoli Versand AG',
          logo: `/assets/logos/${tenantId}/logo.svg`,
          name: 'Jelmoli Versand',
          productSupported: '(Festnetz CHF 0.14 / Min., Mobilfunkpreise max. CHF 0.42 / Min.)',
          sameAs: [
            'https://www.facebook.com/jelmoliversand/',
            'https://www.youtube.com/channel/UCJunBjLDyBOG2mQD-K1bkhQ',
            'https://www.instagram.com/jelmoliversand/',
            'https://de.wikipedia.org/wiki/Jelmoli',
          ],
          telephone: '+41848-840-301',
          url: 'https://www.jelmoli-shop.ch',
          postalCode: '8112',
          privacyNoticePath: '/fr/aide-service/qui-sommes-nous/protection-des-donnees',
          potentialAction: {
            target: 'https://www.jelmoli-shop.ch/s/{search_term}/',
          },
          streetAddress: 'Industriestrasse 19',
        },
      },
    },
    externalScript: {
      kuecheCo: {
        key: 'pAYrueynYRcqna7lGLoVT39kF83Wy3PYENp81Yw0QJqeTyzAjXvc1FwGOzCm',
      },
    },
    headerInformation: {
      de: {
        description:
          'Entdecken Sie aktuelle Fashion- und Wohntrends. Grosse Auswahl ✔ Top-Brands ✔ Gratis Versand &amp; Retoure ✔ Machen Sie sich eine Freude!',
        title: 'Jelmoli Versand - der online Mode und M&ouml;bel Versand',
        canonical: 'https://www.jelmoli-shop.ch',
        robots: 'index,follow',
      },
      fr: {
        description:
          "Description:* Mode et meubles à commander SUR FACTURE - robes, chaussures, lingerie, maillots, sport, meubles et articles techniques. L'embarras du choix sur JELMOLI VERSAND SUISSE",
        title: 'Jelmoli Versand Suisse - vente de mode et meubles à distance',
        canonical: 'https://www.jelmoli-shop.ch',
        robots: 'index,follow',
      },
    },
    // kim is renamed as "customerCentricFeedback"
    customerCentricFeedback: {
      tenantId: '1300',
    },
    dynamicYieldTracking: {
      key: '85fed729032b18a2a240c7c5fd478e30951dd4885b6ae83fbd5e5e6b0b633914',
    },
    geoCountryLayer: {
      enabled: true,
      flag: 'ch',
      alternatives: [
        {
          flag: 'de',
          shops: [{ link: 'https://www.otto.de' }],
        },
        {
          flag: 'at',
          shops: [{ link: 'https://www.ottoversand.at' }],
        },
      ],
    },
    priceFormat: 'NoDelimiterCurrencyAtTheEnd',
  },
  { arrayMerge: customArrayMerge },
);

export const getFullLocale = (localeOrLanguage: string | undefined) =>
  getFullLocaleFromConfig(localeOrLanguage, config);

// eslint-disable-next-line import/no-default-export
export default config;
